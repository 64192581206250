// fix for checkbox checked styles in FireFox, resolves problem
// with check mark position
.select-dropdown .form-check-input label {
  display: block;
}

select.select-initialized {
  display: none !important;
}

.select-arrow {
  color: $form-outline-select-arrow-color;
  text-align: center;
  font-size: $form-outline-select-arrow-font-size;
  position: absolute;
  top: $form-outline-select-arrow-top;
  right: $form-outline-select-arrow-right;

  &:before {
    content: '\25BC';
  }
}

.was-validated .form-control:valid ~ .select-arrow {
  color: $form-outline-select-valid-color;
}

.was-validated .form-control:invalid ~ .select-arrow {
  color: $form-outline-select-invalid-color;
}

.select-clear-btn {
  color: $form-outline-select-clear-btn-color;
  font-size: $form-outline-select-clear-btn-font-size;
  position: absolute;
  top: $form-outline-select-clear-btn-top;
  right: $form-outline-select-clear-btn-right;
  cursor: pointer;

  &:focus {
    color: $form-outline-select-clear-btn-focus-color;
    outline: none;
  }
}

.form-control-sm ~ .select-clear-btn {
  font-size: $form-outline-select-sm-clear-btn-font-size;
  top: $form-outline-select-sm-clear-btn-top;
}

.form-control-lg ~ .select-clear-btn {
  top: $form-outline-select-lg-clear-btn-top;
}

.select-dropdown-container {
  z-index: $form-outline-select-dropdown-container-z-index;
}

.select-dropdown {
  background-color: $form-outline-select-dropdown-bgc;
  box-shadow: $form-outline-select-dropdown-box-shadow;
  margin: 0;
  min-width: $form-outline-select-dropdown-min-width;
  outline: 0;
  position: relative;
  transform: $form-outline-select-dropdown-transform;
  opacity: 0;
  transition: $form-outline-select-dropdown-transition;

  &.open {
    transform: $form-outline-select-dropdown-open-transform;
    opacity: 1;
  }
}

.select-dropdown > .input-group {
  padding: $form-outline-select-dropdown-input-group-padding;
}

.select-label {
  max-width: $form-outline-select-label-max-width;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &.active {
    transform: $form-outline-select-label-active-transform;
  }
}

.form-control-lg ~ .select-label.active {
  transform: $form-outline-select-lg-label-active-transform;
}

.form-control-sm ~ .select-label.active {
  transform: $form-outline-select-sm-label-active-transform;
}

.form-outline .select-label.active ~ .form-notch .form-notch-middle {
  border-top: none;
  border-right: none;
  border-left: none;
}

.select-input {
  cursor: pointer;

  &[disabled] {
    cursor: default;
  }
}

.select-input.focused {
  color: $form-outline-select-input-focused-color;
  outline: 0;

  & ~ .select-label {
    color: $form-outline-select-label-color;
  }

  &::placeholder {
    opacity: 1;
  }

  & ~ .form-notch .form-notch-leading {
    border-top: $form-outline-select-notch-border-width solid $form-outline-select-notch-border-color;
    border-bottom: $form-outline-select-notch-border-width solid $form-outline-select-notch-border-color;
    border-left: $form-outline-select-notch-border-width solid $form-outline-select-notch-border-color;
    transition: $form-outline-select-notch-transition;
  }

  & ~ .form-notch .form-notch-middle {
    border-bottom: $form-outline-select-notch-border-width solid;
    border-color: $form-outline-select-notch-border-color;
    transition: $form-outline-select-notch-transition;
  }

  & ~ .form-notch .form-notch-trailing {
    border-top: $form-outline-select-notch-border-width solid;
    border-bottom: $form-outline-select-notch-border-width solid;
    border-right: $form-outline-select-notch-border-width solid;
    border-color: $form-outline-select-notch-border-color;
    transition: $form-outline-select-notch-transition;
  }
}

.select-input.focused ~ .select-arrow {
  color: $form-outline-select-input-focused-arrow-color;
}

.form-white .select-input:focus ~ .select-arrow {
  color: $form-outline-select-white-focus-arrow-color;
}

.form-white .select-arrow {
  color: $form-outline-select-white-arrow-color;
}

.form-white .select-clear-btn {
  color: $form-outline-select-white-clear-btn;
}

.form-control-sm ~ .select-arrow {
  top: $form-outline-select-sm-arrow-top;
}

.form-control-lg ~ .select-arrow {
  top: $form-outline-select-lg-arrow-top;
}

.select-options-wrapper {
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: $form-outline-select-options-wrapper-scrollbar-width;
    height: $form-outline-select-options-wrapper-scrollbar-height;
  }

  &::-webkit-scrollbar-button {
    &:start:decrement,
    &:end:increment {
      display: block;
      height: 0;
      background-color: transparent;
    }
  }

  &::-webkit-scrollbar-track-piece {
    background-color: transparent;
    border-radius: 0;
    border-bottom-right-radius: $form-outline-select-options-wrapper-scrollbar-border-bottom-right-radius;
    border-bottom-left-radius: $form-outline-select-options-wrapper-scrollbar-border-bottom-left-radius;
  }

  &::-webkit-scrollbar-thumb:vertical {
    height: $form-outline-select-options-wrapper-scrollbar-thumb-height;
    background-color: $form-outline-select-options-wrapper-scrollbar-thumb-bgc;
    border-radius: $form-outline-select-options-wrapper-scrollbar-thumb-border-radius;
  }
}

.select-options-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select-option-group-label {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: $form-outline-select-option-group-label-pl;
  padding-right: $form-outline-select-option-group-label-pr;
  font-size: $form-outline-select-option-group-label-font-size;
  font-weight: $form-outline-select-option-group-label-font-weight;
  background-color: transparent;
  color: $form-outline-select-option-group-label-color;
  user-select: none;
}

.select-option-group > .select-option {
  padding-left: $form-outline-select-option-group-select-option-pl;
}

.select-option {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: $form-outline-select-option-color;
  padding-left: $form-outline-select-option-pl;
  padding-right: $form-outline-select-option-pr;
  font-size: $form-outline-select-option-font-size;
  font-weight: $form-outline-select-option-font-weight;
  background-color: transparent;
  user-select: none;

  &:hover:not(.disabled) {
    background-color: $form-outline-select-option-hover-not-disabled-bgc;
  }

  &.active {
    background-color: $form-outline-select-option-active-bgc;
  }

  &.selected.active {
    background-color: $form-outline-select-option-selected-active-bgc;
  }

  &.selected.disabled {
    cursor: default;
    color: $form-outline-select-option-selected-disabled-color;
    background-color: transparent;
  }

  &.selected {
    background-color: $form-outline-select-option-selected-bgc;
  }

  &.disabled {
    cursor: default;
    color: $form-outline-select-option-disabled-color;
  }
}

.select-option-text {
  & .form-check-input {
    margin-right: $form-outline-select-option-text-form-check-input-mr;
  }
}

.select-option-secondary-text {
  font-size: $form-outline-select-option-secondary-text-font-size;
  color: $form-outline-select-option-secondary-text-color;
  display: block;
  line-height: normal;
}

.select-option-icon {
  width: $form-outline-select-option-icon-width;
  height: $form-outline-select-option-icon-height;
}

.select-custom-content {
  padding: $form-outline-select-custom-content-padding;
}

.select-no-results {
  padding-left: $form-outline-select-no-results-pl;
  padding-right: $form-outline-select-no-results-pr;
  display: flex;
  align-items: center;
}

// Select white
.form-white .select-input.focused ~ .select-arrow {
  color: $form-outline-select-white-arrow;
}
