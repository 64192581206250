// Alert

.alert {
  border: 0;
  border-radius: $alert-border-radius;
}

.alert-absolute {
  position: absolute;
}

.alert-fixed {
  position: fixed;
  z-index: $zindex-alert;
}

.parent-alert-relative {
  position: relative;
}
